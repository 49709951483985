<template>
  <div class="page question-page">
    <QHeader />

    <div class="ask">
      <div class="ask-no">
        <h4>第四题</h4>
        <p>QUESTION FOURTH</p>
      </div>
      <div class="ask-line"></div>
      <div class="ask-content">
        <p>这时有一位乐手为你们演奏，</p>
        <p>最后测试完后，您希望听到的是？</p>
      </div>
    </div>

    <img src="../../assets/images/q4.png" class="content" />

    <div class="items">
      <div
        class="item"
        :class="{ active: key === choice }"
        v-for="(item, key) in items"
        :key="key"
        @click="select(key)"
      >
        {{ item }}
      </div>
    </div>

    <div class="action">
      <button class="btn" @click="back">上一题</button>
      <button class="btn next" @click="next">下一题</button>
    </div>
  </div>
</template>
<script>
import QHeader from "../../components/QHeader";
import { mapState } from "vuex";

export default {
  name: "Step4",

  components: { QHeader },

  computed: {
    ...mapState({
      record: (state) => state.detail.record,
    }),
  },

  data() {
    return {
      choice: null,

      items: [
        "A. 悠扬舒缓的钢琴曲",
        "B. 慵懒性感的爵士",
        "C. 节奏明朗的手碟",
        "D. 神秘悠远的管弦",
      ],
    };
  },

  watch: {
    "$store.state.detail.record"(record) {
      this.choice = record[3];
    },
  },

  mounted() {
    this.choice = this.record[3];
    this.$store.dispatch("share/config");
  },

  methods: {
    select(key) {
      this.choice = key;
    },

    back() {
      this.$router.back();
    },

    next() {
      if (![0, 1, 2, 3].includes(this.choice)) {
        this.$layer.msg("请点击您的选择");
        return;
      }

      this.$store.commit("record/choice", [3, this.choice]);
      this.$router.push({
        path: "/result",
        query: {
          open_id: localStorage.getItem("code"),
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
img {
  display: block;

  &.content {
    width: 100%;
  }
}

.ask {
  margin: 40px 0;
}
</style>