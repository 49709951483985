<template>
  <div class="header-question">
    <img src="../../assets/images/header-logo-left.png" />
    <img src="../../assets/images/header-logo-right.png" />
  </div>
</template>
<style scoped lang="less">
.header-question {
  margin: 0 0 20px 0;

  img {
    height: 20px;

    &:last-child {
      float: right;
    }
  }
}
</style>